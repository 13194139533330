import { parseWithZod } from "@conform-to/zod";
import { useForm, getFormProps } from "@conform-to/react";
import cache from "#app/utils/cache-server.ts";
import AppShell from "#app/components/dashboard/app-shell.tsx";
import { packData } from "#app/constant/link-data";
import { createMongoAbility } from "@casl/ability";
import { invariantResponse } from "@epic-web/invariant";
import {
  json,
  type ActionFunctionArgs,
  type HeadersFunction,
  type LinksFunction,
  type LoaderFunctionArgs,
  type MetaFunction,
} from "@remix-run/node";
import {
  Links,
  Meta,
  Outlet,
  Scripts,
  ScrollRestoration,
  useLoaderData,
  useFetcher,
  useFetchers,
} from "@remix-run/react";
import { withSentry } from "@sentry/remix";
import { HoneypotProvider } from "remix-utils/honeypot/react";
import { z } from "zod";
import { GeneralErrorBoundary } from "./components/error-boundary.tsx";
import { EpicProgress } from "./components/progress-bar.tsx";
import { useToast } from "./components/toaster.tsx";
import { Icon, href as iconsHref } from "./components/ui/icon.tsx";
import { EpicToaster } from "./components/ui/sonner.tsx";
import tailwindStyleSheetUrl from "./styles/tailwind.css?url";
import { authenticator, getUser } from "./utils/auth.server.ts";
import { ClientHintCheck, getHints, useHints } from "./utils/client-hints.tsx";
import { useRequestInfo } from "./utils/request-info.ts";
import { getEnv } from "./utils/env.server.ts";
import { honeypot } from "./utils/honeypot.server.ts";
import { i18n, useChangeLanguage } from "./utils/i18n.ts";
import { i18next } from "./utils/i18next.server.ts";
import { getDomainUrl } from "./utils/misc.tsx";
import { useNonce } from "./utils/nonce-provider.ts";
import { type Theme, setTheme, getTheme } from "./utils/theme.server.ts";
import { makeTimings, time } from "./utils/timing.server.ts";
import { getToast } from "./utils/toast.server.ts";
import { getScope, createScopeHeaders } from "./utils/scope.server.ts";
import { BasicUserSchema } from "./utils/validation/auth-validation.ts";
import React from "react";
import { RootContext } from "#app/context/root-context.ts";
import { api as base } from "#app/utils/http.server.ts";

export const links: LinksFunction = () => {
  return [
    // Preload svg sprite as a resource to avoid render blocking
    { rel: "preload", href: iconsHref, as: "image" },
    {
      rel: "manifest",
      href: "/site.webmanifest",
      crossOrigin: "use-credentials",
    } as const, // necessary to make typescript happy
    //These should match the css preloads above to avoid css as render blocking resource
    // { rel: "icon", type: "image/svg+xml", href: "/favicons/favicon.svg" },
    { rel: "stylesheet", href: tailwindStyleSheetUrl },
  ].filter(Boolean);
};

export const meta: MetaFunction<typeof loader> = ({ data }) => {
  return [
    { title: data ? "Kelas Digital" : "Error | Kelas Digital" },
    { name: "description", content: `Kelas Digital Application` },
  ];
};

export async function loader({ request }: LoaderFunctionArgs) {
  const timings = makeTimings("root loader");
  const session = await time(() => authenticator.isAuthenticated(request), {
    timings,
    type: "getSession",
    desc: "getSession in root",
  });

  const user = session ? BasicUserSchema.parse(session) : null;

  const locale = await i18next.getLocale(request);
  const { toast, headers: toastHeaders } = await getToast(request);
  const honeyProps = honeypot.getInputProps();

  return json(
    {
      user,
      requestInfo: {
        hints: getHints(request),
        origin: getDomainUrl(request),
        path: new URL(request.url).pathname,
        locale,
        userPrefs: {
          theme: getTheme(request),
        },
      },
      ENV: getEnv(),
      toast,
      honeyProps,
    },
    {
      headers: toastHeaders ?? {},
    },
  );
}

export const handle = {
  links: [{ label: "Dashboard", link: "/", key: "root" }],
  // In the handle export, we can add a i18n key with namespaces our route
  // will need to load. This key can be a single string or an array of strings.
  // TIP: In most cases, you should set this to your defaultNS from your i18n config
  // or if you did not set one, set it to the i18next default namespace "translation"
  i18n: "common",
};

export const headers: HeadersFunction = ({ loaderHeaders }) => {
  const headers = {
    "Server-Timing": loaderHeaders.get("Server-Timing") ?? "",
  };
  return headers;
};

const ThemeFormSchema = z.object({
  theme: z.enum(["system", "light", "dark"]),
});

export async function action({ request }: ActionFunctionArgs) {
  const formData = await request.formData();

  const theme = formData.get("theme");
  if (theme) {
    const submission = parseWithZod(formData, {
      schema: ThemeFormSchema,
    });

    invariantResponse(
      submission.status === "success",
      "Invalid theme received",
    );

    const { theme } = submission.value;

    const responseInit = {
      headers: { "set-cookie": setTheme(theme) },
    };
    return json({ result: submission.reply() }, responseInit);
  } else {
    const user = await getUser(request);
    const { scope } = await getScope(request);
    const label = formData.get("label");
    const value = formData.get("value");

    if (label && value) {
      const headers = await createScopeHeaders({ label, value });

      const abilitiesKey = user.id + "-ability-" + scope?.value;
      cache.delete(abilitiesKey);
      return json(
        { label, value },
        {
          headers,
        },
      );
    }
  }
}

function Document({
  children,
  nonce,
  theme = "light",
  locale = i18n.fallbackLng,
  env = {},
  allowIndexing = true,
}: {
  children: React.ReactNode;
  nonce: string;
  theme?: Theme;
  locale?: string;
  env?: Record<string, string>;
  allowIndexing?: boolean;
}) {
  return (
    <html lang={locale} className={`${theme} h-full overflow-x-hidden`}>
      <head>
        <ClientHintCheck nonce={nonce} />
        <Meta />
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width,initial-scale=1" />
        {allowIndexing ? null : (
          <meta name="robots" content="noindex, nofollow" />
        )}
        <Links />
      </head>
      <body className="bg-background text-foreground">
        {children}
        <script
          nonce={nonce}
          dangerouslySetInnerHTML={{
            __html: `window.ENV = ${JSON.stringify(env)}`,
          }}
        />
        <ScrollRestoration nonce={nonce} />
        <Scripts nonce={nonce} />
      </body>
    </html>
  );
}

function App() {
  const data = useLoaderData<typeof loader>();
  const nonce = useNonce();
  const theme = useTheme();
  const allowIndexing = data.ENV.ALLOW_INDEXING !== "false";
  const { locale } = data.requestInfo;
  useChangeLanguage(locale);
  useToast(data.toast);

  const user = data?.user ? { user: data?.user } : null;
  const memoizedData = React.useMemo(() => user, [JSON.stringify(user)]);

  return (
    <Document
      locale={data.requestInfo.locale}
      nonce={nonce}
      theme={theme}
      allowIndexing={allowIndexing}
      env={data.ENV}
    >
      <div className="flex h-screen flex-col justify-between">
        <div className="flex-1">
          <RootContext.Provider value={memoizedData}>
            <Outlet />
          </RootContext.Provider>
        </div>
      </div>
      <EpicToaster closeButton position="top-center" theme={theme} />
      <EpicProgress />
    </Document>
  );
}

function AppWithProviders() {
  const data = useLoaderData<typeof loader>();
  return (
    <HoneypotProvider {...data.honeyProps}>
      <App />
    </HoneypotProvider>
  );
}

export default withSentry(AppWithProviders);

/**
 * @returns the user's theme preference, or the client hint theme if the user
 * has not set a preference.
 */
export function useTheme() {
  const hints = useHints();
  const requestInfo = useRequestInfo();
  const optimisticMode = useOptimisticThemeMode();
  if (optimisticMode) {
    return optimisticMode === "system" ? hints.theme : optimisticMode;
  }
  return requestInfo.userPrefs.theme ?? hints.theme;
}

/**
 * If the user's changing their theme mode preference, this will return the
 * value it's being changed to.
 */
export function useOptimisticThemeMode() {
  const fetchers = useFetchers();
  const themeFetcher = fetchers.find((f) => f.formAction === "/");

  if (themeFetcher && themeFetcher.formData) {
    const submission = parseWithZod(themeFetcher.formData, {
      schema: ThemeFormSchema,
    });

    if (submission.status === "success") {
      return submission.value.theme;
    }
  }
}

export function ThemeSwitch({
  userPreference,
}: { userPreference?: Theme | null }) {
  const fetcher = useFetcher<typeof action>();

  const [form] = useForm({
    id: "theme-switch",
    lastResult: fetcher.data?.result,
  });

  const optimisticMode = useOptimisticThemeMode();
  const mode = optimisticMode ?? userPreference ?? "system";
  const nextMode =
    mode === "system" ? "light" : mode === "light" ? "dark" : "system";
  const modeLabel = {
    light: (
      <Icon name="sun">
        <span className="sr-only">Light</span>
      </Icon>
    ),
    dark: (
      <Icon name="moon">
        <span className="sr-only">Dark</span>
      </Icon>
    ),
    system: (
      <Icon name="laptop">
        <span className="sr-only">System</span>
      </Icon>
    ),
  };

  return (
    <fetcher.Form action="/" method="POST" {...getFormProps(form)}>
      <input type="hidden" name="theme" value={nextMode} />
      <div className="flex gap-2">
        <button
          type="submit"
          className="flex h-8 w-8 cursor-pointer items-center justify-center"
        >
          {modeLabel[mode]}
        </button>
      </div>
    </fetcher.Form>
  );
}

export function ErrorBoundary() {
  // the nonce doesn't rely on the loader so we can access that
  const nonce = useNonce();

  // NOTE: you cannot use useLoaderData in an ErrorBoundary because the loader
  // likely failed to run so we have to do the best we can.
  // We could probably do better than this (it's possible the loader did run).
  // This would require a change in Remix.

  // Just make sure your root route never errors out and you'll always be able
  // to give the user a better UX.

  return (
    <Document nonce={nonce}>
      <GeneralErrorBoundary />
    </Document>
  );
}
